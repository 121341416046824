import React, {useState} from 'react'
import arrowImg from "./img/right-arrow.png"
import slide1 from "./img/all_plots/average correlation.png"
import slide2 from "./img/all_plots/average_happiness.png"
import slide3 from "./img/all_plots/health_importance_linechart.png"
import slide4 from "./img/all_plots/america_government.png"
import slide5 from "./img/all_plots/italy_government.png"
import slide6 from "./img/all_plots/world_government.png"
import slide7 from "./img/all_plots/costa_davorio.png"
import slide8 from "./img/all_plots/ucraina.png"
import slide9 from "./img/all_plots/venezuela.png"
import slide10 from "./img/all_plots/afghanistan_all.png"


import "./css/analisi.css"

export default function AnalisiView(props){
    const datas = [<Slide titolo={"Cosa influisce sulla felicità"} immagine={slide1}
                descrizione={"In generale si può notare una forte correlazione tra economia, famiglia, salute e felicità. Questo non vuol dire che i fattori che influenzano la felicità solo questi 3, bensì che al crescere di uno corrisponde un aumento degli altri"}/>,
        <Slide titolo={"La felicità aumenta!"} immagine={slide2}
                descrizione={"In figura la felicità media di tutti i paesi del mondo. Si può notare una costante seppur lieve crescita, indicante che in generale vi è stato un miglioramento delle condizioni di vita."}/>,
        <Slide titolo={"Covid e salute"} immagine={slide3}
               descrizione={"L'idea di questa analisi era vedere che impatto avesse avuto il COVID19 nel mondo. La figura prima mostra che non c'è stato un grande impatto sulla felicità ma questo grafico mette in evidenza come ci sia stato un incremento del fattore salute.\n" +
                   "come ci si potrebbe aspettare la salute ha acquisito maggior peso sulla valutazione della felicità di un individuo dal 2020 in poi"}/>,
        <Slide titolo={"Eventi in america"} immagine={slide4}
               descrizione={"L'intenzione di questo studio era di vedere se il periodo di governo di Trump (molto controverso) e le proteste relative a Black Lives Matter (compreso il caso Rittenhouse) avessero avuto un influenza in positivo o in negativo sul'influenza del governo nella felicità del cittadino.\n" +
                   "Non si nota alcuna sostanziale variazione, 2 ipotesi:\n" +
                   "Poco interesse verso la cronaca oppure forte polarizzazione (gli interessati ed i disinteressati si bilanciano)."}/>,
        <Slide titolo={"...e in italia?"} immagine={slide5}
               descrizione={"Il grafo precedente mette in evidenza un incremento del fattore \"Trust\" nell'anno 2020, quindi abbiamo indagato più a fondo.\n" +
                   "Emerge una relazione tra il Covid19 e l'impatto che il governo ha sulla felicità dei cittadini in USA come in italia."}/>,
        <Slide titolo={"Fiducia nei governi"} immagine={slide6}
               descrizione={"Sempre indagando sulla relazione tra il Covid19 e il parametro \"Trust\" abbiamo analizzato il fenomeno su scala globale.\n" +
                   "Emerge un trend che indica che a seguito del Covid19 la felicità delle persone è molto più influenzata dalla scelte di governo rispetto agli scorsi anni.\n" +
                   "Questo soprattutto nei paesi del primo mondo."} />,
        <Slide titolo={"Altro dal mondo: Costa D'avorio"} immagine={slide7}
               descrizione={"Si puo notare come negli anni in Costa D'Avorio la felicità si gradualmente aumentata.Una delle possibili cause che possono avere portato a quest'aumneto può essere stata la fine del conflitto civile che durava ormai da 13 anni avvenuta nel 2017. Questo avvenimento però potrebbe aver causato anche una diminuizione maggiore per quanto riguarda la fiducia nel governo."}/>,
        <Slide titolo={"Altro dal mondo: Venezuela"} immagine={slide8}
               descrizione={"In questo caso l'evento che potrebbe aver causato una diminuizione della felicità può essere: le forti proteste scaturite dall'arresto di alcuni leader politici molto in rilievo nel paese nel 2017. Quindi questo evento potrebbe aver causato anche una diminuizione nella fiducia nel governo."}/>,
        <Slide titolo={"Altro dal mondo: Ucraina"} immagine={slide9}
                descrizione={"In Ucraina nel 2017 sono iniziate delle massicce occupazioni del territorio da parte dei Russi e ovviamente questo evento ha scaturito una drastica diminuizione della felicità e soprattutto della fiducia nel governo."}/>,
        <Slide titolo={"Altro dal mondo: Afghanistan"} immagine={slide10}
                descrizione={"L'Afghanistan in generale presentava valori di felicità, di libertà ecc molto bassi, ma  nonostante la conquista da parte dei Talebani non hanno avuto dei drastici cambiamenti in questi campi."}/>]

    const [slideIndex, setSlideIndex] = useState(0)

    const nextSlide = () => {
        if(slideIndex<datas.length-1){
            setSlideIndex(slideIndex+1)
        }else if(slideIndex===datas.length-1){
            setSlideIndex(0)
        }
    }

    const prevSlide = () => {
        if(slideIndex>0){
            setSlideIndex(slideIndex-1)
        }else{
            setSlideIndex(datas.length-1)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }


    return (
        <div className="container-slider">
            <BtnSlider moveSlide={prevSlide} direction={"prev"}/>
            <BtnSlider moveSlide={nextSlide} direction={"next"} />

            <div>
                {datas[slideIndex]}
            </div>

            <div className="container-dots">
                {Array.from({length: datas.length}).map((item, index) => (
                    <div
                        onClick={() => moveDot(index)}
                        className={slideIndex === index ? "dot active" : "dot"}
                    ></div>


                ))}
            </div>

        </div>
    )
}

function BtnSlider(props){
    return(
        <button className={"btn-slider-wrap btn-wrap-"+props.direction} onClick={props.moveSlide}>
            <img onClick={props.moveSlide} className={"btn-slider "+ props.direction} src={arrowImg}/>
        </button>
    )
}

function Slide(props){
    return(<div className={"slide-wrap "+props.class}>
        <h1 className={"slide-title "}>{props.titolo}</h1>
        <div className={"slide-descr "}>{props.descrizione}</div>
        <div className={"slide-img "}>
            <img src={props.immagine}/>
        </div>
    </div>)
}